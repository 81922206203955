<template>
<div>

  <v-card>
    <v-card-title>
      {{titulo}}
      <v-spacer></v-spacer>
      <p> Cuota número: <span class="font-weight-black"> {{ local_quote }} </span> </p>
      <v-spacer></v-spacer>
      <!-- <v-text-field
        v-model="search"
        append-icon="fas fa-search"
        label="Buscar"
        single-line
        hide-details
      ></v-text-field> -->
    </v-card-title>

    <v-data-table
      :headers="headers"
      :items="banks"
      :search="search"
      sort-by="nombre"
      class="elevation-1"
      :loading="loading"
      loading-text="Consultando datos... Espere por favor"
    >

    <template v-slot:[`item.payment_amount`]="{ item }">
        <div>
          {{ numberWithCommas(item.payment_amount) }}
        </div>
    </template>

    <template v-slot:[`item.picture`]="{ item }">
      <v-icon 
      v-if="item.path_picture"
      color="purple darken-3"
      @click="seePicture(item.path_picture)"
      >fas fa-image</v-icon>
    </template>


    <template v-slot:[`item.bill`]="{ item }">
      <v-icon 
      v-if="item.path_bill"
      color="purple darken-3"
      @click="seeDocument(item.path_bill)"
      >fas fa-file-pdf</v-icon>
    </template>



      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="700px">
            <template v-slot:activator="{ on }">
                <v-btn large rounded outlined ref="boton2" color="success" dark v-on="on"> 
                  Agregar Pago
                <v-icon right dark >
                  fas fa-plus
                </v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-form ref="form" v-model="valid">
                    <v-row>

                      <v-col cols="12" sm="12" md="6">
                        <v-menu
                          ref="menu"
                          v-model="menu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="editedItem.payment_date"
                              :rules="editedItem.payment_dateRules"
                              label="Fecha de Pago"
                              prepend-icon="mdi-calendar"
                              v-bind="attrs"
                              v-on="on"
                              readonly
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="editedItem.payment_date"
                            no-title
                            @input="menu = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>

                      <v-col cols="12" sm="12" md="6">
                        <v-text-field
                        v-model="editedItem.payment_amount"
                        :rules="editedItem.payment_amountRules"
                        label="Monto a Pagar"
                        v-mask="currencyMask"
                        ></v-text-field>
                      </v-col>
                      
                      <v-col cols="12" sm="12" md="12">
                        <v-select
                            v-model="editedItem.id_payment_method"
                            :items="methods"
                            label="Método de Pago"
                            item-text="name"
                            item-value="id"
                            :rules="editedItem.payment_methodRules"
                            chips
                            solo
                            rounded
                            clearable
                            return-object
                          >
                        </v-select>
                      </v-col>

                      <v-col cols="12" sm="12" md="12">
                        <v-textarea
                          v-model="editedItem.comment"
                          filled
                          auto-grow
                          label="Descripción"
                          rows="3"
                          shaped
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
                <v-btn color="blue darken-1" text @click="save">Guardar</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogDelete" max-width="450">
            <v-card>
              <v-card-title>
                <span>Desea eliminar el pago del: {{ dateFormat(editedItem.payment_date) }}?</span>
                <v-spacer></v-spacer>
              </v-card-title>

              <v-card-actions>
                <v-btn color="green darken-1" text @click="close">Cancelar</v-btn>
                <v-btn color="green darken-1" text @click="eliminar">Aceptar</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogUpload" max-width="650">
            <v-card>
              <v-card-title>
                <span>Subir documentos de pago del {{ dateFormat(editedItem.payment_date) }}</span>
                <v-spacer></v-spacer>
              </v-card-title>

              <v-card-text>
                <template>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                        <v-file-input
                          v-model="editedItem.pay_image"
                          color="deep-purple accent-4"
                          counter
                          :label="path_label_image"
                          placeholder="Selecciona el recibo de pago"
                          prepend-icon="mdi-camera"
                          accept="image/png, image/jpeg, image/bmp"
                          outlined
                          :show-size="1000"
                        >
                          <template v-slot:selection="{ index, text }">
                            <v-chip
                              v-if="index < 2"
                              color="deep-purple accent-4"
                              dark
                              label
                              small
                            >
                              {{ text }}
                            </v-chip>

                            <span
                              v-else-if="index === 2"
                              class="text-overline grey--text text--darken-3 mx-2"
                            >
                              +{{ files.length - 2 }} File(s)
                            </span>
                          </template>
                        </v-file-input>
                      </v-col>

                      <v-col cols="12" sm="12" md="12">
                        <v-file-input
                          v-model="editedItem.pay_file"
                          color="deep-purple accent-4"
                          counter
                          :label="path_label_file"
                          placeholder="Selecciona el documento de pago"
                          prepend-icon="mdi-paperclip"
                          accept="application/pdf"
                          outlined
                          :show-size="1000"
                        >
                          <template v-slot:selection="{ index, text }">
                            <v-chip
                              v-if="index < 2"
                              color="deep-purple accent-4"
                              dark
                              label
                              small
                            >
                              {{ text }}
                            </v-chip>

                            <span
                              v-else-if="index === 2"
                              class="text-overline grey--text text--darken-3 mx-2"
                            >
                              +{{ files.length - 2 }} File(s)
                            </span>
                          </template>
                        </v-file-input>
                      </v-col>
                  </v-row>
                  
                </template>
              </v-card-text>

              <v-card-actions>

                <v-row no-gutters style="flex-wrap: nowrap;">
                  <v-btn
                    class="flex-grow-1 flex-shrink-0"
                    large
                    rounded
                    outlined
                    color="gray"
                    @click="close"
                  >Cancelar</v-btn>
                  <v-btn
                    :loading="loading3"
                    :disabled="loading3"
                    class="flex-grow-1 flex-shrink-0 white--text"
                    large
                    rounded
                    color="deep-purple"
                    @click="upload"
                    >Subir
                      <v-icon right dark > mdi-cloud-upload </v-icon>
                    </v-btn>
                </v-row>

              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <v-tooltip top color="purple">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" small class="mr-3" @click="uploadFile(item)">fas fa-file-upload</v-icon>
          </template>
          <span>Subir documentos</span>
        </v-tooltip>
        <v-icon color="success" small class="mr-2" @click="editItem(item)">fas fa-edit</v-icon>
        <v-icon color="red" small @click="deleteItem(item)">fas fa-trash-alt</v-icon>
      </template>
      
    </v-data-table>
  </v-card>
</div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import axios from "axios";
import * as moment from 'moment';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

export default {
  name: "Bancos",
  data: () => ({
    dialog: false,
    banks: [],
    methods: [],
    dialogUpload: false,
    dialogDelete: false,
    search: "",
    loading: "",
    loading3: false,
    titulo: "Pagos",
    headers: [
      { text: "Fecha", align: "left", width:"120", value: "payment_date" },
      { text: "Método de Pago", align: "left", value: "payment_method" },
      { text: "Cantidad", align: "left", value: "payment_amount" },
      { text: "Descripción", align: "left", value: "comment" },
      { text: "Comprobante", align: "left", value: "picture" },
      { text: "Recibo", align: "left", value: "bill" },
      { text: "Acciones", value: "action", width:"100", sortable: false }
    ],
    editedIndex: -1,
    
    currencyMask: createNumberMask({
                      prefix: '',
                      allowDecimal: true,
                      includeThousandsSeparator: true,
                      allowNegative: false,
                    }),
    valid: true,
    menu: false,
    imageVisible: false,
    loadedImages: {},
    editedItem: {
      id: "",
      payment_date: "",
      comment: "",
      payment_dateRules: [v => !!v || "Selecciona la fecha"],
      id_payment_method: "",
      payment_methodRules: [v => !!v || "Selecciona un método de pago"],
      payment_amount: "",
      payment_amountRules: [v => !!v || "Escribe una cantidad"],

      pay_file: [],
      pay_image: [],
      active: false
    },
    defaultItem: {
      payment_date: "",
      comment: "",
      payment_dateRules: [v => !!v || "Selecciona la fecha"],
      id_payment_method: "",
      payment_methodRules: [v => !!v || "Selecciona un método de pago"],
      payment_amount: "",
      payment_amountRules: [v => !!v || "Escribe una cantidad"],
      active: false
    }
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo Registro" : "Editar Registro";
    },
    path_label_file(){
      return this.editedItem.filename_bill;
    },
    path_label_image(){
      return this.editedItem.filename_picture;
    },
    ...mapState(["db","headers_db"]),
  },

  watch: {
    dialog(val) {
      val || this.close();
    }
  },

  methods: {
    ...mapActions(["cerrarSesion", "validateSession"]),
    // Metodos base de datos
    async getBanks(){
      // await axios.get(this.db + 'collections/payments/' + 150,
      await axios.get(this.db + 'collections/payments/' + this.number_quote,
        {
          headers: this.headers_db
        }).then(response => {
          this.banks = response.data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.validateSession(error).then((resp) => { if(resp) this.getBanks(); })
        });
    },

    async payment_methods(){
      await axios.get(this.db + 'collections/payment_methods',
        {
          headers: this.headers_db
        }).then(response => {
          this.methods = response.data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.validateSession(error).then((resp) => { if(resp) this.payment_methods(); })
        });
    },

    addProcess(payload){
      payload.number_quote = this.number_quote;
      console.log(payload);
      axios.post(this.db + 'collections/payment/add', payload,
      {
        headers: this.headers_db
      })
      .then((response) => {
        console.log(response);
        this.$store.dispatch("setSnackbar", {
          text: response.data.message,
        });
        this.getBanks();
      })
      .catch((error) => {
        console.log(error);
        this.validateSession(error).then((resp) => { if(resp) this.addProcess(payload); })
      });

    },

    updateProcess(payload){
      console.log(payload);
      axios.post(this.db + 'collections/payment/update', payload,
      {
        headers: this.headers_db
      })
      .then((response) => {
        console.log(response.data.message);
        this.$store.dispatch("setSnackbar", {
          text: response.data.message,
        });
        this.getBanks();
      })
      .catch((error) => {
        console.log(error);
        this.validateSession(error).then((resp) => { if(resp) this.updateProcess(payload); })
      });

    },

    deleteProcess(id){

      axios.post(this.db + 'collections/payment/delete', { id },
      {
        headers: this.headers_db
      })
      .then((response) => {
        console.log(response);
        this.banks = this.banks.filter(doc => {
        return doc.id != id
        })
      })
      .catch((error) => {
        console.log(error);
        this.validateSession(error).then((resp) => { if(resp) this.deleteProcess(id); })
      });

    },

    async upload() {
      this.loader = 'loading3'
      let reload = false;
      let headers = 
      {
          'Content-Type': 'multipart/form-data',
          'Authorization': 'BEARER ' + localStorage.token
      };
      
      if (this.editedItem.pay_file != undefined && this.editedItem.pay_file.size > 0) {
          let formData = new FormData();
          // files
          formData.append('path', 'path_bill');
          formData.append('path_name', 'filename_bill');
          formData.append('name', this.editedItem.pay_file.name);
          formData.append('files', this.editedItem.pay_file);
          formData.append('id', this.editedItem.id);
          
          // return;
          await axios.post(this.db + "payment/upload", formData,
          {
            headers:headers
          })
          .then(response => {
              this.$store.dispatch("setSnackbar", {
                text: 'Documento subido con éxito',
              });
              console.log({ response });
              reload = true;
          })
          .catch(error => {
              if (error.response.status == 401) {
                reload = true;
              }
              this.$store.dispatch("setSnackbar", {
                text: 'No se ha podido subir el documento',
                color: 'error'
              });
          });
      } else {
          console.log("there are no files.");
      }

      if (this.editedItem.pay_image != undefined && this.editedItem.pay_image.size > 0) {
          let formData = new FormData();
          console.log(this.editedItem.pay_image);
          // files
          formData.append('path', 'path_picture');
          formData.append('path_name', 'filename_picture');
          formData.append('name', this.editedItem.pay_image.name);
          formData.append('files', this.editedItem.pay_image);
          formData.append('id', this.editedItem.id);
          
          // return;
          await axios.post(this.db + "payment/upload", formData,
          {
            headers:headers
          })
          .then(response => {
              this.$store.dispatch("setSnackbar", {
                text: 'Imagen subida con éxito',
              });
              console.log({ response });
              reload = true;
          })
          .catch(error => {
              console.log(error);
              if (error.response.status == 401) {
                reload = true;
              }
              this.$store.dispatch("setSnackbar", {
                text: 'No se ha podido subir la imagen',
                color: 'error'
              });
          });
      } else {
          console.log("there are no files.");
      }

      if (reload) {
        this.getBanks();
      }
      this.dialogUpload = false;
    },

    async seeDocument(path){
      await axios.get(this.db + 'customer/download/' + path,
      {
        responseType: 'arraybuffer',
        headers: {
            // 'Content-Type': 'multipart/form-data',
            "Content-type": "application/x-www-form-urlencoded",
            'Authorization': 'BEARER ' + localStorage.token
        }
      })
      .then(response => {
          console.log(response );
          let blob = new Blob([response.data], { type: 'application/pdf' } ),
          url = window.URL.createObjectURL(blob)

          window.open(url);
      })
      .catch(error => {
          console.log( error );
          if (error.response.status == 401) {
            this.getBanks();
          }
      });
    },

    async seePicture(path){
      await axios.get(this.db + 'customer/download/' + path,
      {
        responseType: 'arraybuffer',
        headers: {
            // 'Content-Type': 'multipart/form-data',
            "Content-type": "application/x-www-form-urlencoded",
            'Authorization': 'BEARER ' + localStorage.token
        }
      })
      .then(response => {
          console.log(response );
          let blob = new Blob([response.data], { type: 'image/jpeg' } ),
          url = window.URL.createObjectURL(blob)

          window.open(url);
      })
      .catch(error => {
          console.log( error );
          if (error.response.status == 401) {
            this.getBanks();
          }
      });
    },

    // FIN Metodos base de datos


    // Metodos Generales
    dateFormat(date) {
        return moment(date).format('DD/MM/YYYY');
    },

    editItem(item) {
      console.log(item);
      this.editedIndex = this.banks.indexOf(item);
      this.dialog = true;
      this.editedItem = Object.assign({}, item);
    },

    uploadFile(item){
      console.log(item);
      this.editedItem = Object.assign({}, item);
      this.dialogUpload = true;
    },

    deleteItem(item) {
      console.log(item.id);

      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    close() {
      this.dialog = false;
      this.dialogUpload = false;
      // this.$emit('closeModal', false)
      this.dialogDelete = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.currencyMask = "";
        this.editedIndex = -1;
      }, 300);
    },

    save() {
      console.log("cuota",this.number_quote);
      if (this.$refs.form.validate()) {
        this.editedItem.id_payment_method = this.editedItem.id_payment_method.id ? this.editedItem.id_payment_method.id : this.editedItem.id_payment_method;
        if (this.editedIndex > -1) {
          Object.assign(this.banks[this.editedIndex], this.editedItem);
          this.updateProcess(this.editedItem);
        } else {
          this.banks.push(this.editedItem);
          this.addProcess(this.editedItem);
        }
        this.close();
      }
    },

    eliminar() {
      this.deleteProcess(this.editedItem.id);
      this.close();
    },

    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    showImage(imageSrc, itemId) {
      if (!this.loadedImages[itemId]) {
        this.loadImage(imageSrc, itemId);
      }
      this.imageVisible = itemId;
    },

    hideImage() {
      this.imageVisible = false;
    }
  },
  created() {
    this.getBanks();
    this.payment_methods();
  },
  props: {
    number_quote: String,
    local_quote: String
  }
};
</script>
